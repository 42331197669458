<template>
  <div class="section section-main">
    <v-container class="base-content pt-5">
      <v-row>
        <v-col cols="12">
          <h1 class="pt-2 pb-2">Политика конфиденциальности</h1>
        </v-col>
        <v-col cols="12">
          <h2>Политика в отношении обработки персональных данных</h2><br/>
          <h3>1. Общие положения</h3><br/>
          <p>Настоящая политика обработки персональных данных составлена в&nbsp;соответствии с&nbsp;требованиями
            Федерального закона от&nbsp;27.07.2006
            . &#8470;&nbsp;152-ФЗ&laquo;О&nbsp;персональных данных&raquo; и&nbsp;определяет порядок
            обработки персональных данных и&nbsp;меры по&nbsp;обеспечению безопасности персональных данных,
            предпринимаемые Меркурий Онлайн (далее&nbsp;&mdash; Оператор).<br/>
            1.1. Оператор ставит своей важнейшей целью и&nbsp;условием осуществления своей деятельности соблюдение прав
            и&nbsp;свобод человека и&nbsp;гражданина при обработке его персональных данных, в&nbsp;том числе защиты прав
            на&nbsp;неприкосновенность частной жизни, личную и&nbsp;семейную тайну.<br/>
            1.2. Настоящая политика Оператора в&nbsp;отношении обработки персональных данных (далее&nbsp;&mdash;
            Политика) применяется ко&nbsp;всей информации, которую Оператор может получить о&nbsp;посетителях
            веб-сайта
            <a href="http://an-merkuriy.ru/.">http://an-merkuriy.ru/.</a></p><br/>
          <h3>2. Основные понятия, используемые в&nbsp;Политике</h3><br/>
          <p>2.1. Автоматизированная обработка персональных данных&nbsp;&mdash; обработка персональных данных с&nbsp;помощью
            средств вычислительной техники;<br/>
            2.2. Блокирование персональных данных&nbsp;&mdash; временное прекращение обработки персональных данных (за&nbsp;исключением
            случаев, если обработка необходима для уточнения персональных данных);<br/>
            2.3.
            Веб-сайт&nbsp;&mdash; совокупность графических и&nbsp;информационных материалов, а&nbsp;также
            программ для ЭВМ и&nbsp;баз данных, обеспечивающих их&nbsp;доступность в&nbsp;сети интернет по&nbsp;сетевому
            адресу <a href="http://an-merkuriy.ru/">http://an-merkuriy.ru/</a>;<br/>
            2.4. Информационная система персональных данных&nbsp;&mdash; совокупность содержащихся в&nbsp;базах данных
            персональных данных, и&nbsp;обеспечивающих их&nbsp;обработку информационных технологий и&nbsp;технических
            средств;<br/>
            2.5. Обезличивание персональных данных&nbsp;&mdash; действия, в&nbsp;результате которых невозможно
            определить без использования дополнительной информации принадлежность персональных данных конкретному
            Пользователю или иному субъекту персональных данных;<br/>
            2.6. Обработка персональных данных&nbsp;&mdash; любое действие (операция) или совокупность действий
            (операций), совершаемых с&nbsp;использованием средств автоматизации или без использования таких средств с&nbsp;персональными
            данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
            удаление, уничтожение персональных данных;<br/>
            2.7. Оператор&nbsp;&mdash; государственный орган, муниципальный орган, юридическое или физическое лицо,
            самостоятельно или совместно с&nbsp;другими лицами организующие и&nbsp;(или) осуществляющие обработку
            персональных данных, а&nbsp;также определяющие цели обработки персональных данных, состав персональных
            данных, подлежащих обработке, действия (операции), совершаемые с&nbsp;персональными данными;<br/>
            2.8. Персональные данные&nbsp;&mdash; любая информация, относящаяся прямо или косвенно к&nbsp;определенному
            или определяемому Пользователю веб-сайта
            <a href="http://an-merkuriy.ru/">http://an-merkuriy.ru/</a>;<br/>
            2.9. Пользователь&nbsp;&mdash; любой посетитель веб-сайта
            <a href="http://an-merkuriy.ru/">http://an-merkuriy.ru/</a>;<br/>
            2.10. Предоставление персональных данных&nbsp;&mdash; действия, направленные на&nbsp;раскрытие персональных
            данных определенному лицу или определенному кругу лиц;<br/>
            2.11. Распространение персональных данных&nbsp;&mdash; любые действия, направленные на&nbsp;раскрытие
            персональных данных неопределенному кругу лиц (передача персональных данных) или на&nbsp;ознакомление с&nbsp;персональными
            данными неограниченного круга лиц, в&nbsp;том числе обнародование персональных данных в&nbsp;средствах
            массовой информации, размещение в&nbsp;информационно-телекоммуникационных
            сетях или предоставление доступа к&nbsp;персональным данным каким-либо
            иным способом;<br/>
            2.12. Трансграничная передача персональных данных&nbsp;&mdash; передача персональных данных на&nbsp;территорию
            иностранного государства органу власти иностранного государства, иностранному физическому или иностранному
            юридическому лицу;<br/>
            2.13. Уничтожение персональных данных&nbsp;&mdash; любые действия, в&nbsp;результате которых персональные
            данные уничтожаются безвозвратно с&nbsp;невозможностью дальнейшего восстановления содержания персональных
            данных в&nbsp;информационной системе персональных данных и&nbsp;(или) уничтожаются материальные носители
            персональных данных.</p><br/>
          <h3>3. Оператор может обрабатывать следующие персональные данные Пользователя</h3><br/>
          <p>3.1. Электронный адрес;<br/>
            3.2. Номера телефонов;<br/>
            3.3. Имя;<br/>
            3.4. Также на&nbsp;сайте происходит сбор и&nbsp;обработка обезличенных данных о&nbsp;посетителях (в т. ч.
            файлов &laquo;cookie&raquo;) с&nbsp;помощью сервисов интернет-статистики
            (Яндекс Метрика и&nbsp;Гугл Аналитика и&nbsp;других).<br/>
            3.5. Вышеперечисленные данные далее по&nbsp;тексту Политики объединены общим понятием Персональные
            данные.</p><br/>
          <h3>4. Цели обработки персональных данных</h3><br/>
          <p>4.1. Цель обработки персональных данных Пользователя&nbsp;&mdash; информирование Пользователя посредством
            отправки электронных писем; предоставление доступа Пользователю к&nbsp;сервисам, информации и/или
            материалам, содержащимся на&nbsp;веб-сайте.<br/>
            4.2. Также Оператор имеет право направлять Пользователю уведомления о&nbsp;новых продуктах и&nbsp;услугах,
            специальных предложениях и&nbsp;различных событиях. Пользователь всегда может отказаться от&nbsp;получения
            информационных сообщений, направив Оператору письмо на&nbsp;адрес электронной почты privacy@thismywebsite&middot;com
            с&nbsp;пометкой &laquo;Отказ от&nbsp;уведомлений о&nbsp;новых продуктах и&nbsp;услугах и&nbsp;специальных
            предложениях&raquo;.<br/>
            4.3. Обезличенные данные Пользователей, собираемые с&nbsp;помощью сервисов интернет-статистики, служат для
            сбора информации о&nbsp;действиях Пользователей на&nbsp;сайте, улучшения качества сайта и&nbsp;его
            содержания.</p><br/>
          <h3>5. Правовые основания обработки персональных данных</h3><br/>
          <p>5.1. Оператор обрабатывает персональные данные Пользователя только в&nbsp;случае их&nbsp;заполнения и/или
            отправки Пользователем самостоятельно через специальные формы, расположенные на&nbsp;сайте
            <a href="http://an-merkuriy.ru/.">http://an-merkuriy.ru/.</a> Заполняя соответствующие формы и/или отправляя
            свои персональные данные Оператору, Пользователь
            выражает свое согласие с&nbsp;данной Политикой.<br/>
            5.2. Оператор обрабатывает обезличенные данные о&nbsp;Пользователе в&nbsp;случае, если это разрешено в&nbsp;настройках
            браузера Пользователя (включено сохранение файлов &laquo;cookie&raquo; и&nbsp;использование технологии
            JavaScript).</p><br/>
          <h3>6. Порядок сбора, хранения, передачи и&nbsp;других видов обработки персональных данных</h3><br/>
          <p>Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации
            правовых, организационных и&nbsp;технических мер, необходимых для выполнения в&nbsp;полном объеме требований
            действующего законодательства в&nbsp;области защиты персональных данных.<br/>
            6.1. Оператор обеспечивает сохранность персональных данных и&nbsp;принимает все возможные меры, исключающие
            доступ к&nbsp;персональным данным неуполномоченных лиц.<br/>
            6.2. Персональные данные Пользователя никогда, ни&nbsp;при каких условиях не&nbsp;будут переданы третьим
            лицам, за&nbsp;исключением случаев, связанных с&nbsp;исполнением действующего законодательства.<br/>
            6.3. В&nbsp;случае выявления неточностей в&nbsp;персональных данных, Пользователь может актуализировать их&nbsp;самостоятельно,
            путем направления Оператору уведомление на&nbsp;адрес электронной почты Оператора privacy@thismywebsite&middot;com
            с&nbsp;пометкой &laquo;Актуализация персональных данных&raquo;.<br/>
            6.4. Срок обработки персональных данных является неограниченным. Пользователь может в&nbsp;любой момент
            отозвать свое согласие на&nbsp;обработку персональных данных, направив Оператору уведомление посредством
            электронной почты на&nbsp;электронный адрес Оператора privacy@thismywebsite&middot;com с&nbsp;пометкой
            &laquo;Отзыв согласия на&nbsp;обработку персональных данных&raquo;.</p><br/>
          <h3>7. Трансграничная передача персональных данных</h3><br/>
          <p>7.1. Оператор до&nbsp;начала осуществления трансграничной передачи персональных данных обязан убедиться в&nbsp;том,
            что иностранным государством, на&nbsp;территорию которого предполагается осуществлять передачу персональных
            данных, обеспечивается надежная защита прав субъектов персональных данных.<br/>
            7.2. Трансграничная передача персональных данных на&nbsp;территории иностранных государств, не&nbsp;отвечающих
            вышеуказанным требованиям, может осуществляться только в&nbsp;случае наличия согласия в&nbsp;письменной
            форме субъекта персональных данных на&nbsp;трансграничную передачу его персональных данных и/или исполнения
            договора, стороной которого является субъект персональных данных.</p><br/>
          <h3>8. Заключительные положения</h3><br/>
          <p>8.1. Пользователь может получить любые разъяснения по&nbsp;интересующим вопросам, касающимся обработки его
            персональных данных, обратившись к&nbsp;Оператору с&nbsp;помощью электронной почты privacy@thismywebsite&middot;com.<br/>
            8.2. В&nbsp;данном документе будут отражены любые изменения политики обработки персональных данных
            Оператором. Политика действует бессрочно до&nbsp;замены ее&nbsp;новой версией.<br/>
            8.3. Актуальная версия Политики в&nbsp;свободном доступе расположена в&nbsp;сети Интернет по&nbsp;адресу
            <a href="http://an-merkuriy.ru/account/privacy-policy.">http://an-merkuriy.ru/account/privacy-policy.</a>
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>

<style scoped>
  .section-main {
    background: white;
  }
</style>
